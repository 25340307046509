<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="1000"
      :visible="visible"
      :confirm-loading="loading"
      title="资产列表"
      @update:visible="updateVisible"
      @ok="save"
    >
      <ud-pro-table
        ref="table"
        row-key="id"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
        :toolbar="false"
      >
        <template #img="{ record }">
          <div v-if="record.assetsImg">
            <a-image :width="100" :height="100" :src="record.assetsImg + '.thumbnail'"/>
          </div>
        </template>
      </ud-pro-table>
    </a-modal>
  </div>
</template>

<script>
import * as formInfoApi from '@/api/ams/formInfo'

export default {
  name: 'withdrawalAssets',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {},
  data() {
    return {
      columns: [
        {
          width: 48,
          align: 'center',
          fixed: 'left',
          customRender: ({ index }) => index + 1
        },
        {
          title: '资产编号',
          align: 'center',
          fixed: 'left',
          dataIndex: 'assetsCode',
          sorter: true
        },
        {
          title: '图片',
          width: 100,
          align: 'center',
          dataIndex: 'assetsImg',
          slots: { customRender: 'img' }
        },
        {
          title: '资产名称',
          width: 100,
          align: 'center',
          dataIndex: 'assetsName',
          sorter: true
        },
        {
          title: '分类编码',
          width: 100,
          align: 'center',
          dataIndex: 'categoryCode',
          sorter: true
        },
        {
          title: '分类名称',
          align: 'center',
          dataIndex: 'categoryName',
          sorter: true
        },
        {
          title: '品牌',
          align: 'center',
          dataIndex: 'brand',
          sorter: true
        },
        {
          title: '型号',
          align: 'center',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '数量',
          align: 'center',
          dataIndex: 'count',
          sorter: true
        },
        {
          title: '单价',
          align: 'center',
          dataIndex: 'unitPrice',
          sorter: true
        },
        {
          title: '金额',
          align: 'center',
          dataIndex: 'total',
          sorter: true
        },
        {
          title: '取得时间',
          align: 'center',
          dataIndex: 'buyDate',
          sorter: true,
          customRender: ({ text }) =>
            this.$util.toDateString(text, 'yyyy-MM-dd')
        },
        {
          title: '所属单位',
          align: 'center',
          dataIndex: 'affiliatedUnitName',
          sorter: true
        },
        {
          title: '公物仓',
          align: 'center',
          dataIndex: 'warehouseName',
          sorter: true
        },
        {
          title: '备注',
          align: 'center',
          dataIndex: 'remark',
          sorter: true
        }
      ],
      // 表单数据
      form: this.data,
      // 表格搜索条件
      where: {},
      // 表格选中数据
      selection: [],
      // 表格排序条件
      order: {}
    }
  },
  watch: {
    data() {
      this.where.formId = this.data.id
      this.$nextTick(() => {
        this.reload()
      })
    }
  },
  methods: {
    datasource(option, callback) {
      this.where.formId = this.data.id
      if (!this.where.formId) {
        const data2 = []
        callback(data2, 0)
        return
      }
      formInfoApi
        .assets({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 保存编辑 */
    save() {
      this.updateVisible(false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1 })
    }
  }
}
</script>

<style scoped lang='less'>
.form {
  :deep(.disabledColor) {
    background: #fff;
    opacity: 1;
    input[disabled],
    select[disabled],
    span {
      color: black;
    }
    .ant-input-disabled,
    .ant-select-selector {
      background: #fff;
    }
  }
}

:deep(.ant-image-img){
  height: 100%;
}
</style>
